import styled from "styled-components/native";
import { Platform } from "react-native";

import { Text2, Text5, Text6 } from "~/components/text";
import { colors, spacers } from "~/styles/theme";
import { getWebContainerStyle } from "~/utils/web";

type Props = {
  isHighlighted?: boolean;
  isSelected?: boolean;
  highlightColor?: string;
  hasComment?: boolean;
};

const getBackgroundColor = ({ isSelected, highlightColor }: Props) =>
  isSelected ? "#eaeaea" : highlightColor || colors.white;

export const Container = styled.View`
  ${getWebContainerStyle(960)}
`;

export const Title = styled(Text6)`
  font-family: SFProDisplayMedium;
  font-weight: 600;
  padding-top: 30px;
  padding-horizontal: ${spacers.ss7};
`;

export const TextContent = styled.View`
  padding: ${spacers.ss7};
`;

export const Number = styled(Text2)<Props>`
  color: ${colors.amber600};
  font-family: ${Platform.OS === "ios" ? "LoraSuperscript" : "Lora"};
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  background-color: ${getBackgroundColor};
`;

export const Verse = styled(Text5)<Props>`
  color: ${({ hasComment }) => (hasComment ? colors.teal700 : colors.gray800)};
  font-family: Lora;
  letter-spacing: 0.5px;
  line-height: 36px;
  ${({ isHighlighted }) => (isHighlighted ? "text-decoration: underline" : "")};
  background-color: ${getBackgroundColor};
`;

export const ChapterSubtitle = styled(Text5)<Props>`
  color: ${colors.gray800};
  font-family: SFProDisplayMedium;
  font-weight: 600;
`;

export const CommentIcon = styled.TouchableOpacity`
  padding-right: ${spacers.ss3};
  top: -10px;
`;
