import * as React from "react";
import {
  Text,
  TextLayoutEventData,
  NativeSyntheticEvent,
  GestureResponderEvent,
  LayoutChangeEvent,
} from "react-native";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import { useNavigation } from "@react-navigation/native";

import * as routes from "~/constants/routes";
import { Verse as VerseType } from "~/state/bible/types";
import { colors } from "~/styles/theme";
import { useAppDispatch, useAppSelector } from "~/state/hooks";
import {
  getBibleNotesVersesByChapterId,
  getBiblehighlightsByChapterId,
} from "~/state/bible-notes/selectors";

import { EditMenu } from "../edit-menu";
import { highlightColors } from "../edit-menu/constants";
import {
  Container,
  Title,
  TextContent,
  Number as VerseNumber,
  Verse,
  CommentIcon,
  ChapterSubtitle,
} from "./styles";
import { addBibleHighlight } from "~/state/bible-notes/actions";
import { isWeb } from "~/utils/platform";
import { useModal } from "~/state/modal/hook";
import { BibleNoteViewEditComponent } from "~/screens/notes-bible-view";

interface Props {
  title: string;
  verses: VerseType[];
  scrollToPosition: (position: number) => void;
  highlightedVerses?: string[];
  bookId: string;
  chapterId: number;
}

const getClosestValue = (values: number[], number: number) => {
  let maxValue = 0;
  for (let i = 0; i < values.length; i++) {
    if (number < values[i]) {
      return maxValue;
    }
    maxValue = values[i];
  }
  return maxValue;
};

const normalise = (verse: string) => Number(verse.split(".")[1] || verse);

const isPartOfTheBlock = (verses: string[], verse: string) => {
  const normalisedVerses = verses.map(normalise);
  const normalisedVerse = normalise(verse);

  return (
    normalisedVerses[0] - normalisedVerse === 1 ||
    normalisedVerses[normalisedVerses.length - 1] - normalisedVerse === -1
  );
};

export const Content = React.memo<Props>(
  ({
    bookId,
    chapterId,
    title,
    verses,
    scrollToPosition,
    highlightedVerses = [],
  }) => {
    const hasVersesToHighlight = Array.isArray(highlightedVerses);
    const verseToScroll = hasVersesToHighlight ? highlightedVerses[0] : "";
    const [linesPositions, setLinesPositions] = React.useState<number[]>([]);
    const [showTooltip, setShowTooltip] = React.useState(false);
    const [tooltipPosition, setTooltipPosition] = React.useState(0);
    const [containerPosition, setContainerPosition] = React.useState(0);
    const [selectedVerses, setSelectedVerses] = React.useState<string[]>([]);
    const [highlights, setHighlights] = React.useState<{
      [key: string]: number;
    }>({});
    const { showModal, hideModal } = useModal();

    const bibleComments = useAppSelector((state) =>
      getBibleNotesVersesByChapterId(state, chapterId)
    );
    const savedHighlights = useAppSelector((state) =>
      getBiblehighlightsByChapterId(state, { bookId, chapterId })
    );
    const bibleHighlights = React.useMemo(
      () => ({ ...(savedHighlights || {}), ...highlights }),
      [savedHighlights, highlights]
    );

    const navigation = useNavigation();
    const dispatch = useAppDispatch();

    // Get the vertical position of each line (verse) in the content
    const onTextLayout = React.useCallback(
      (event: NativeSyntheticEvent<TextLayoutEventData>) => {
        const lines = event?.nativeEvent?.lines || [];
        setLinesPositions(lines.map(({ y }) => y));

        if (!verseToScroll) {
          return;
        }
        const versePosition = lines.find(({ text }) =>
          text.includes(`${verseToScroll} `)
        )?.y;
        if (versePosition) {
          scrollToPosition(versePosition);
        }
      },
      [verseToScroll, scrollToPosition]
    );

    const onWebTextLayout = React.useCallback(
      (index: number) => (event: NativeSyntheticEvent<TextLayoutEventData>) => {
        if (!verseToScroll || Number(verseToScroll) !== index + 1) {
          return;
        }
        // @ts-ignore
        const versePosition = event.nativeEvent?.layout?.y;

        if (versePosition) {
          scrollToPosition(versePosition);
        }
      },
      [verseToScroll, scrollToPosition]
    );

    const handleSelectedVerse = React.useCallback(
      (verseId: string) => {
        if (selectedVerses.includes(verseId)) {
          setSelectedVerses(
            selectedVerses.filter((verse) => verse !== verseId)
          );
          setShowTooltip(false);
          return;
        }
        // Deselect the previous selection if it is not a part of the same "block"
        if (isPartOfTheBlock(selectedVerses, verseId)) {
          setSelectedVerses([...selectedVerses, verseId]);
        } else {
          setSelectedVerses([verseId]);
        }

        setShowTooltip(true);
      },
      [selectedVerses]
    );

    const onHighlightPress = React.useCallback(
      (index: number) => {
        const hasHighlightOverlap = Object.keys(bibleHighlights).some((verse) =>
          selectedVerses.includes(verse)
        );
        // If we deselect the highlight that has been already saved, we need to update it in the Firestore as well
        if (!hasHighlightOverlap) {
          if (index < 0) {
            setSelectedVerses([]);
            setShowTooltip(false);
            return;
          }
          if (!selectedVerses.length) {
            return;
          }
        }

        const newHighlights = selectedVerses.reduce((acc, item) => {
          if (index < 0) {
            const { [item]: removedVerse, ...rest } = acc;
            return rest;
          }
          return {
            ...acc,
            [item]: index,
          };
        }, bibleHighlights);
        setHighlights(newHighlights);
        setSelectedVerses([]);
        setShowTooltip(false);

        dispatch(
          addBibleHighlight({ bookId, chapterId, highlights: newHighlights })
        );
      },
      [bibleHighlights, selectedVerses, dispatch, bookId, chapterId]
    );

    const onVersePress = React.useCallback(
      (verseId: string) => (event: GestureResponderEvent) => {
        const tapPosition =
          event?.nativeEvent?.locationY || event?.nativeEvent?.pageY || 0;
        const closestVerseTopPosition = getClosestValue(
          linesPositions,
          tapPosition
        );
        const newTooltipPosition = !isWeb
          ? closestVerseTopPosition - 20
          : tapPosition - containerPosition + 80;

        setTooltipPosition(newTooltipPosition);
        handleSelectedVerse(verseId);
      },
      [linesPositions, handleSelectedVerse, containerPosition]
    );

    const onCommentPress = React.useCallback(
      (noteId: string) => {
        // @ts-ignore
        if (!isWeb) {
          navigation.navigate(routes.bibleNoteViewEdit, { noteId });
        } else {
          showModal(
            <BibleNoteViewEditComponent
              noteId={noteId}
              handleDelete={() => {}}
              hideActions
            />
          );
        }
      },
      [navigation, showModal]
    );

    const onContainerLayout = (e: LayoutChangeEvent) => {
      // @ts-ignore
      setContainerPosition(e?.nativeEvent?.layout?.top || 0);
    };

    const handleDeselect = React.useCallback(() => {
      setSelectedVerses([]);
      setShowTooltip(false);
    }, []);

    React.useEffect(() => {
      handleDeselect();
      setHighlights({});
    }, [chapterId, handleDeselect]);

    const fullSelectedVerses = verses
      .filter(({ verseId }) => selectedVerses.includes(verseId))
      .map(({ verseId, content }) => ({ verseId, content: content.trim() }));

    const containerProps = isWeb ? { onLayout: onContainerLayout } : {};

    const editMenuProps = {
      top: tooltipPosition,
      onHighlightPress: onHighlightPress,
      selectedVerses: fullSelectedVerses,
      bookId: bookId,
      chapterId: chapterId,
      onNavigation: handleDeselect,
      isOpen: showTooltip,
      onDismiss: () => setShowTooltip(false),
    };

    return (
      <>
        {isWeb && showTooltip ? <EditMenu {...editMenuProps} /> : null}
        <Container {...containerProps}>
          {title ? <Title>{title}</Title> : null}
          <TextContent>
            {!isWeb && showTooltip ? <EditMenu {...editMenuProps} /> : null}

            <Text onTextLayout={onTextLayout}>
              {verses.map(({ verseId, content }, index) => {
                const id: string = verseId.split(".")[1];
                const lastCharacter = content.charAt(content.length - 1);
                const text = lastCharacter === "\n" ? `${content} \n` : content;
                const textWithSubtitle = content.match(
                  /(.*?)(\n[A-Z][^\n]*\n)$/
                );
                const verseText = textWithSubtitle
                  ? textWithSubtitle[1]?.trim()
                  : text;
                const subtitle = textWithSubtitle
                  ? textWithSubtitle[2]?.trim()
                  : "";
                const isHighlighted =
                  hasVersesToHighlight && highlightedVerses.includes(id);
                const isSelected = selectedVerses.includes(verseId);
                const highlightColor =
                  highlightColors[bibleHighlights[verseId]] || colors.white;
                const noteId = bibleComments[verseId];

                const webProps = isWeb
                  ? { onPress: onVersePress(verseId) }
                  : {};
                const verseProps = isWeb
                  ? { onLayout: onWebTextLayout(index) }
                  : {};

                return [
                  <VerseNumber
                    isSelected={isSelected}
                    highlightColor={highlightColor}
                    key={verseId}
                    {...verseProps}
                  >
                    {` ${id} `}
                  </VerseNumber>,
                  noteId ? (
                    <Text
                      onPress={() => onCommentPress(noteId)}
                      suppressHighlighting
                      key={`comment-${verseId}`}
                    >
                      {" "}
                      <Icon
                        name="comment-edit"
                        size={16}
                        color={colors.teal700}
                      />{" "}
                    </Text>
                  ) : null,
                  <Verse
                    isHighlighted={isHighlighted}
                    isSelected={isSelected}
                    highlightColor={highlightColor}
                    hasComment={!!noteId}
                    key={`text-${verseId}`}
                    onLongPress={onVersePress(verseId)}
                    suppressHighlighting
                    {...webProps}
                  >
                    {verseText}
                  </Verse>,
                  subtitle ? (
                    <ChapterSubtitle key={`subtitle-${verseId}`}>
                      {`\n\n${subtitle}\n\n`}
                    </ChapterSubtitle>
                  ) : null,
                ];
              })}
            </Text>
          </TextContent>
        </Container>
      </>
    );
  }
);
